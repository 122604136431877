import React from 'react'
import { images } from 'config/images'

export const EcommerceDevComProps = {
  ppcbannerProps: {
    heading: (
      <>
        <span className="highlight-head">Ecommerce Development Company</span>
      </>
    ),
    para: (
      <>
        Build a strong online presence for selling your products using high
        performant, scalable and{' '}
        <span className="color-through">stable e-commerce application</span>{' '}
        with advanced analytics
      </>
    ),
    formCTA: 'Talk to our Experts',
  },
  buildApplicationProps: {
    buildApplicationHeading: (
      <>
        Building e-commerce solutions for{' '}
        <span className="highlight-head">Fortune 1000 companies</span>
      </>
    ),
    buildAppPara: (
      <>
        With more than 8 years of{' '}
        <span className="color-through">eCommerce development experience</span>,
        our in-house team can help you leverage BigCommerce Stencil, Shopify
        Liquid or Magento customizations to transform your website into a
        scalable and intuitive shopping experience.
      </>
    ),
    buildVid: images.demoVideo,
    posterAlt: 'Concepting',
    lightboxImgVid: 'Rsk2-XQhCAk',
    videoNote: 'Play video to see how we work',
  },
  clientLogoPrpos: {
    clientLogoHeading: (
      <>
        Trusted by <span className="color-through">900+ happy clients</span>{' '}
        including these Fortune companies
      </>
    ),
    clientLogos: [
      {
        clientLogosrc: images.redbull,
        clientLogosrcg: images.redbullg,
        clientLogoAlt: 'Redbull',
      },
      {
        clientLogosrc: images.FujiFilmGray,
        clientLogosrcg: images.fujiFilmLogo,
        clientLogoAlt: 'Fuji Film',
      },
      {
        clientLogosrc: images.renesaslogoGray,
        clientLogosrcg: images.renesasLogo,
        clientLogoAlt: 'Renesas',
      },
      {
        clientLogosrc: images.Hyundai_Glovis_logoGray,
        clientLogosrcg: images.hyundaiGlovisLogo,
        clientLogoAlt: 'Hyundai Glovis',
      },
      {
        clientLogosrc: images.boag,
        clientLogosrcg: images.boa,
        clientLogoAlt: 'Bank of America',
      },
      {
        clientLogosrc: images.ciscog,
        clientLogosrcg: images.cisco,
        clientLogoAlt: 'Cisco',
      },
      {
        clientLogosrc: images.mitsubishilogoGray,
        clientLogosrcg: images.mitsubishiLogo,
        clientLogoAlt: 'Mitsubishi Electric',
      },
      {
        clientLogosrc: images.TrygGray,
        clientLogosrcg: images.trygCLogo,
        clientLogoAlt: 'Tryg',
      },
      {
        clientLogosrc: images.cameog,
        clientLogosrcg: images.cameo,
        clientLogoAlt: 'Cameo',
      },
      {
        clientLogosrcg: images.fihCLogo,
        clientLogosrc: images.FIHGray,
        clientLogoAlt: 'Fih',
      },
      {
        clientLogosrc: images.HiltonGray,
        clientLogosrcg: images.hiltonLogo,
        clientLogoAlt: 'Hilton',
      },
      {
        clientLogosrc: images.kirloskarlogoGray,
        clientLogosrcg: images.kirloskarBrothersLogo,
        clientLogoAlt: 'kirloskar Brothers',
      },
    ],
    clientCount: '900+',
    clienttitle: 'Happy Clients',
  },
  ppcServiceProps: {
    heading: (
      <>
        Our Key Services as an
        <br />
        <span className="highlight-head">iOS application</span> development
        company
      </>
    ),
    serviceContents: [
      {
        id: '1',
        serviceHeading: 'Ecommerce Strategy and Consulting',
        serviceParagraph: (
          <>
            Strategic planning along with technology infrastructure and
            strategic planning will enable you to stay competitive in ecommerce
            business.
          </>
        ),
        serviceIcon: images.customSoftwareIcon,
        serviceAlt: 'Automation',
        serviceClass: 'very-light-pink',
      },
      {
        id: '2',
        serviceHeading: 'Custom Ecommerce Storefront Designs',
        serviceParagraph: (
          <>
            <strong>Improving user experience</strong> with custom templates,
            easy product and user management, that are cost-effective, provide
            better branding, organize inventory, and simplify processes.
          </>
        ),
        serviceIcon: images.softwareTesting2,
        serviceAlt: 'Mobile',
        serviceClass: 'pale',
      },
      {
        id: '3',
        serviceHeading: 'Custom Shopping Carts',
        serviceParagraph: (
          <>
            Reduce cart abandonment with functionalities such as{' '}
            <strong>real-time store inventory</strong>, quick checkout, offer
            custom discounts, abandonment check-out recovery etc.
          </>
        ),
        serviceIcon: images.enterprise,
        serviceAlt: 'Manual',
        serviceClass: 'pale-grey',
      },
      {
        id: '4',
        serviceHeading: 'CMS Development',
        serviceParagraph: (
          <>
            We develop Content Management Systems (CMS) to localize content,
            prices, weights, and <strong>UX based on user profiles</strong>,
            location, history, and behavior.
          </>
        ),
        serviceIcon: images.webAppDevIcon,
        serviceAlt: 'Software',
        serviceClass: 'ice-blue',
      },
      {
        id: '5',
        serviceHeading: '3rd Party Integration',
        serviceParagraph: (
          <>
            Using <strong>custom API integrations</strong> you can leverage CRM,
            CMS, accounting, marketing automation, ERP, shipping solutions.
          </>
        ),
        serviceIcon: images.dedicatedSoftware,
        serviceAlt: 'DevOps',
        serviceClass: 'ice',
      },
      {
        id: '6',
        serviceHeading: 'Dedicated ERP System',
        serviceParagraph: (
          <>
            Improve coordination between departments with a{' '}
            <strong>centralized ERP</strong> to have a real-time view of the
            current business situation.
          </>
        ),
        serviceIcon: images.productDevelopment,
        serviceAlt: 'Performance',
        serviceClass: 'pale-lavender',
      },
    ],
  },
  workingBenefitsProps: {
    workingBenefitsHeading: (
      <>
        <span className="highlight-head">Benefits</span> of working with <br />
        Simform
      </>
    ),
    workingBenefitsProps: [
      {
        workingBenefitsClass: 'very-light-blue-two',
        workingBenefitsTitle: 'Rapid application delivery using Agile',
        workingBenefitsContent: (
          <>
            Customers today need improved speed, quality, and reliability of
            ecommerce applications. Our <strong>Agile practice</strong> helps
            you build multi-platform apps with faster iterations, delivery
            times, and with higher quality.
          </>
        ),
        workingBenefitsPatern: images.processFlowLightBlue,
      },
      {
        workingBenefitsClass: 'very-light-blue-two',
        workingBenefitsTitle:
          'Rapid Innovation using Microservices architecture',
        workingBenefitsContent: (
          <>
            We implement{' '}
            <strong>Microservice architecture in e-commerce</strong> which
            allows for continuous deployment, increased uptime, and partial
            updates.
          </>
        ),
        workingBenefitsPatern: images.processFlowLightBlueDown,
      },
      {
        workingBenefitsClass: 'very-light-blue-two',
        workingBenefitsTitle:
          'Architecture that perfectly responds to your goals',
        workingBenefitsContent: (
          <>
            Reactive architecture for creating{' '}
            <strong>resilient and flexible applications</strong> that elegantly
            handle ‘live’ data, high load, and bring impeccable UX with its high
            responsiveness.
          </>
        ),
      },
    ],
  },
  caseStudyProps: {
    caseStudyheading: (
      <>
        Case studies on how{' '}
        <span className="highlight-head">iOS applications</span> helped these
        companies
      </>
    ),
    caseStudiesContents: [
      {
        caseStudiesTitle:
          'Building EV charging platform to revolutionize the way we power',
        caseStudiesParaGraph:
          '…clean and quiet on-site power to substitute or supplement the use of diesel generators for a variety of remote power needs…',
        caseStudiesClassName: 'duck-egg-blue',
        caseStudiesImgAlt: 'Mobile Mockup',
        caseURL: '/case-studies/freewire-scalable-vehicle-charging/',
      },
      {
        caseStudiesTitle:
          'Improving the online web experience for International Hockey Federation (FIH)',
        caseStudiesParaGraph:
          '…increased user engagement, usability, discoverability, while providing modern user experience (UX)…',
        caseStudiesClassName: 'light-peach',
        caseStudiesImgAlt: 'Safari',
        caseURL: '/case-studies/fih/',
      },
      {
        caseStudiesTitle: 'Helping luxury shoe brand go digital',
        caseStudiesParaGraph:
          "…take customized orders by applying various combinations of fabrics, motifs, and monograms based on the customer's preferences…",
        caseStudiesClassName: 'light-sky-blue',
        caseStudiesImgAlt: 'Ipad',
        caseURL: '/case-studies/stubbs-woottons/',
      },
      {
        caseStudiesTitle: 'Helping retailers modernize shopping experience',
        caseStudiesParaGraph:
          '…solving the multi-billion dollar problem of faster checkouttimes, customer loyalty programs, and deeper analytics...',
        caseStudiesClassName: 'light-grey-two',
        caseStudiesImgAlt: 'Device',
        caseURL: '/case-studies/swift-shopper-retail-checkout/',
      },
      {
        caseStudiesTitle: 'Shortcut to get featured on Redbull website',
        caseStudiesParaGraph:
          '...custom scalable solution that can easily accommodate their event engagement needs…',
        caseStudiesClassName: 'light-sky-blue',
        caseStudiesImgAlt: 'Device',
        caseURL: '/case-studies/redbull-photobooth/',
      },
    ],
  },
  featureResourceProps: {
    heading: 'Featured Resources',
    featureContents: [
      {
        blogLink: 'https://www.simform.com/ecommerce-app-using-serverless/',
        featurecardTitle:
          'How to Build an E-commerce App using Serverless Technology?',
      },
      {
        blogLink: 'https://www.simform.com/blog/build-ecommerce-app-reactjs/',
        featurecardTitle: 'How to build an eCommerce app using ReactJS',
      },
      {
        blogLink: 'https://www.simform.com/how-to-develop-ecommerce-apps/',
        featurecardTitle: 'How to Make an eCommerce App',
      },
    ],
  },
  ppcGetTouchProps: {
    formHeading: 'Get in Touch',
    personImg: images.contactJignesh,
    personImg2x: images.contactJignesh2x,
    personName: 'Jignesh Solanki',
    personDesignation:
      'Employing a consultative approach and building a team of tech experts to deliver the best solution.',
    personEmail: 'jignesh@simform.com',
    buttonName: 'Call us now',
    buttonURL: '/contact/',
  },
}
